import Layout from "@/views/layout/index.vue";

// 动态路由
export const asyncRoutes = [
  // 数据管理
  // {
  //     path: "/data",
  //     component: Layout,
  //     meta: {title: "数据管理", icon: "drainageshujuguanli"},
  //     redirect: "/data/scada-data",
  //     children: [
  //
  //         // {
  //         //   path: "/data/flow-chart",
  //         //   component: () => import("@/views/datamanager/FlowChart.vue"),
  //         //   meta: { title: "生产工艺", name: "" },
  //         // },
  //         // {
  //         //     path: "/data/video-monitor",
  //         //     component: () => import("@/views/datamanager/videomoniting.vue"),
  //         //     meta: {title: "视频监控", name: "nvr_channel"},
  //         // },
  //         // {
  //         //     path: "/data/entranceGuardManagement",
  //         //     component: () => import("@/views/datamanager/entranceGuardManagement/index"),
  //         //     meta: {title: "门禁管理", name: "nvr_channel"},
  //         // },
  //         // {
  //         //     path: "/data/digital_twin",
  //         //     component: () => import("@/views/datamanager/digitaltwin.vue"),
  //         //     meta: {title: "工艺孪生", name: "digital_twin"},
  //         // },
  //         {
  //             path: "/data/mapmanager",
  //             component: () => import("@/views/datamanager/mapmanager.vue"),
  //             meta: {title: "地图管理", name: "gis_map"}
  //         },
  //         // {
  //         //   path: "/data/water-model",
  //         //   component: () => import("@/views/datamanager/WaterModel.vue"),
  //         //   meta: { title: "水力模型", name: "" },
  //         // },
  //
  //         //  KPI 管理
  //         // {
  //         //     path: "/data/kpi",
  //         //     component: () => import("@/views/datamanager/kpi/index.vue"),
  //         //     meta: {title: "KPI管理", name: "kpi"}
  //         // },
  //
  //         // 计算任务
  //         // {
  //         //     path: "/data/report",
  //         //     component: () => import("@/views/datamanager/report/index.vue"),
  //         //     meta: {title: "报表管理", name: "report"}
  //         // },
  //
  //
  //
  //     ],
  // },

  // {
  //     path: "/customize_config",
  //     component: Layout,
  //     meta: {title: "应用配置", icon: "drainagexitong-gongnengpeizhi"},
  //     children: [
  //
  //
  //
  //     ]
  // },

  // {
  //   path:"/leakage-control",
  //   component:Layout,
  //   meta:{ title:"漏控管理",icon:"drainagesupervision"},
  //   // redirect:"/onlinemodel/mapauthorization",
  //   children:[
  //     {
  //       path: "/leakage-control/partition-setting",
  //       component: () => import("@/views/LeakageControl/PartitionSetting/index.vue"),
  //       meta: { title:"分区配置", name: "dma" },
  //     },
  //     {
  //       path:"/leakage-control/monitoring-analysis",
  //       component: () => import("@/views/LeakageControl/MonitoringAnalysis/index.vue"),
  //       meta:{title:"监控分析  ",name:"dma"}
  //     },
  //     // {
  //     //   path:"/controlCenter/testVedio",
  //     //   component: () => import("@/views/echartsLinkage/testVedio.vue"),
  //     //   meta:{title:"测试vedio  ",name:"ctl_plan"}
  //     // }
  //   ]
  // }

  /*---------------------新的菜单权限-----------------------*/
  // 系统设置
  {
    path: "/settings",
    component: Layout,
    meta: { title: "系统设置", icon: "drainagexitong-gongnengpeizhi" },
    redirect: "/settings/user-control",
    children: [
      {
        path: "/settings/apk-config",
        component: () => import("@/views/customiseConfig/apkConfig/index.vue"),
        meta: { title: "apk管理", name: "apk_manage" },
      },
      // {
      //     path: "/settings/user-control",
      //     component: () => import("@/views/settings/UserControl.vue"),
      //     meta: {title: "用户管理", name: "user"},
      // },
      {
        path: "/settings/role",
        component: () => import("@/views/settings/Role.vue"),
        meta: { title: "角色管理", name: "role" },
      },
      {
        path: "/settings/organ",
        component: () => import("@/views/settings/Organ.vue"),
        meta: { title: "机构管理", name: "organization" },
      },
      {
        path: "/settings/system",
        component: () => import("@/views/settings/System.vue"),
        meta: { title: "系统配置", name: "system" },
      },
      {
        path: "/settings/sub-system",
        component: () => import("@/views/settings/门户配置/index.vue"),
        meta: { title: "门户配置", name: "system_portal" },
      },
      {
        path: "/settings/navigation-config",
        component: () => import("@/views/settings/navigationconfig/index.vue"),
        meta: { title: "新门户配置", name: "system_portal" },
      },
      {
        path: "/settings/dict",
        component: () => import("@/views/settings/dict/index.vue"),
        meta: { title: "字典管理", name: "dict" },
      },
      /*人员管理*/
      {
        path: "/settings/personManager",
        component: () => import("@/views/settings/personManager/index.vue"),
        meta: { title: "用户管理", name: "user" },
      },
      /*部门管理*/
      {
        path: "/settings/department",
        component: () => import("@/views/settings/department/index.vue"),
        meta: { title: "部门管理", name: "department" },
      },
      /*审计日志*/
      {
        path: "/settings/log",
        component: () => import("@/views/settings/审计日志/index.vue"),
        meta: { title: "审计日志", name: "audit_log" },
      },
      {
        path: "/settings/open-api",
        component: () => import("@/views/开放接口/index.vue"),
        meta: {title: "开放接口", name: "openapi_ts" }, //name值是后端的资源
      },
    ],
  },

  // 通知管理
  {
    path: "/notification_manage",
    component: Layout,
    meta: {
      title: "通知管理",
      icon: "drainagea-xinxiyuyinxinxitishixinxi-04",
    },
    redirect: "/notification_manage/index",
    children: [
      {
        path: "/notification_manage/person/index",
        component: () =>
          import("@/views/customiseConfig/通知管理/用户组/index.vue"),
        meta: { name: "notification_manage", title: "用户组" }, //name值是后端的资源
      },
      {
        path: "/notification_manage/channel/index",
        component: () =>
          import("@/views/customiseConfig/通知管理/通知渠道/index.vue"),
        meta: { name: "notification_manage", title: "通知渠道" }, //name值是后端的资源
      },
      {
        path: "/notification_manage/slg/index",
        component: () =>
          import("@/views/customiseConfig/通知管理/通知策略/index.vue"),
        meta: { name: "notification_manage", title: "通知策略" }, //name值是后端的资源
      },
    ],
  },

    // 计算任务
  // 数据采集
  {
    path: "/mathRule",
    component: Layout,
    meta: { title: "计算规则", icon: "drainagecaiji" },
    redirect: "/collection/scada-collect",
    children: [
      {
        path: "/mathRule/module",
        component: () => import("@/views/计算规则/函数模块与管理/index.vue"),
        meta: { title: "函数模板与管理", name: "gateway" },
      },
      {
        path: "/mathRule/task",
        component: () => import("@/views/计算规则/计算任务/index.vue"),
        meta: { title: "计算任务", name: "gis_layer" },
      },
    ],
  },




  // 数据采集
  {
    path: "/collection",
    component: Layout,
    meta: { title: "数据采集", icon: "drainagecaiji" },
    redirect: "/collection/scada-collect",
    children: [
      {
        path: "/collection/scada-collect",
        component: () => import("@/views/collection/SCADACollection.vue"),
        meta: { title: "SCADA数据", name: "gateway" },
      },
      {
        path: "/collection/gis-collect",
        component: () => import("@/views/collection/GISCollection.vue"),
        meta: { title: "GIS数据", name: "gis_layer" },
      },
      {
        path: "/data/video-setting",
        component: () => import("@/views/collection/VideoSetting.vue"),
        meta: { title: "视频网关", name: "nvr_platform" },
      },
      {
        path: "/data/scada-forward",
        component: () => import("@/views/scada转发/index.vue"),
        meta: { title: "scada转发", name: "data_forwarding" },
      },
    ],
  },

  // BIM管理
  {
    path: "/BIM",
    component: Layout,
    meta: { title: "BIM管理", icon: "drainagelishijilu" },
    redirect: "/BIM/craft-twins",
    children: [
      {
        path: "/BIM/craft-twins",
        component: () => import("@/views/collection/CraftTwins.vue"),
        meta: { title: "工艺孪生", name: "digital_twin" },
      },
      // 模型管理
      {
        path: "/BIM/modelManger",
        component: () => import("@/views/datamanager/modelManager/index.vue"),
        meta: { title: "模型配置", name: "digital_twin_bind" },
      },
      {
        path: "/BIM/digitalLayer",
        component: () => import("@/views/datamanager/digitalLayer/index.vue"),
        meta: { title: "图层管理", name: "digital_twin_layer" },
      },
    ],
  },

  // 生产数据
  {
    path: "/production-data",
    component: Layout,
    meta: { title: "生产数据", icon: "drainageshujuguanli" },
    redirect: "/data/scada-data",
    children: [
      {
        path: "/production-data/scada-data",
        component: () => import("@/views/datamanager/SCADAdata.vue"),
        meta: { title: "SCADA数据", name: "metric" },
      },
      // 计算任务
      {
        path: "/production-data/mathTask",
        component: () => import("@/views/datamanager/mathTask/index.vue"),
        meta: { title: "SCADA计算任务", name: "metric_processor" },
      },
      {
        path: "/production-data/scada_config",
        component: () =>
          import("@/views/customiseConfig/scadaConfig/index.vue"),
        meta: { title: "SCADA配置", name: "customize_config" },
      },
      {
        path: "/production-data/tsMeta",
        component: () => import("@/views/datamanager/时序数据/TsMetaViewer.vue"),
        meta: { title: "Ts元数据", name: "metric" },
      },
      {
        path: "/production-data/tsView",
        component: () => import("@/views/datamanager/时序数据/TsViewer.vue"),
        meta: { title: "Ts时序曲线", name: "metric" },
      },
    ],
  },

  /*安防监控*/
  {
    path: "/security-monitoring",
    component: Layout,
    meta: { title: "安防监控", icon: "drainageqiuji" },
    redirect: "/security-monitoring/video-monitor",
    children: [
      {
        path: "/security-monitoring/video-monitor",
        component: () => import("@/views/datamanager/videomoniting.vue"),
        meta: { title: "视频管理", name: "nvr_channel" },
      },
      {
        path: "/security-monitoring/entranceGuardManagement",
        component: () =>
          import("@/views/datamanager/entranceGuardManagement/index"),
        meta: { title: "门禁管理", name: "nvr_acc_ctl" },
      },
      {
        path: "/production-data/channel-config",
        component: () => import("@/views/25D通道配置/index.vue"),
        meta: { title: "通道配置", name: "customize_config" },
      },
    ],
  },

  /*事件管理*/
  {
    path: "/event-managener",
    component: Layout,
    meta: { title: "事件管理", icon: "drainageshijian" },
    redirect: "/event-managener/eventcenter",
    children: [
      {
        path: "/event-managener/eventcenter",
        component: () => import("@/views/eventcenter/eventcenter.vue"),
        meta: { title: "事件中心", name: "alert_event" },
      },
      {
        path: "/event-managener/alarm-config",
        component: () =>
          import("@/views/customiseConfig/alarmConfig/index.vue"),
        meta: { title: "报警规则", name: "alert_watcher" },
      },
      {
        path: "/event-managener/da-config",
        component: () => import("@/views/customiseConfig/DAConfig/index.vue"),
        meta: { title: "自控报警", name: "alert_da_rule" },
      },

      /*安防报警*/
      {
        path: "/event-managener/video-alarm",
        component: () => import("@/views/安防报警/index.vue"),
        meta: { title: "安防报警", name: "alert_nvr_rule" },
      },
    ],
  },

  // 智能巡检
  {
    path: "/PATROL_ALL",
    component: Layout,
    redirect: "/PATROL_ALL/task-manage",
    meta: {
      title: "智慧巡检",
      icon: "drainagexunjianjihuaguanli",
    },
    children: [
      {
        path: "/PATROL_ALL/task-record",
        meta: { title: "任务记录", name: "patrol_v2_task_record" },
        component: () => import("@/views/PATROL/taskRecord/index.vue"), // 路由懒加载写法 只要进入这个路径 才会加载这个路由
      },
      {
        path: "/PATROL_ALL/inspection-wrong",
        meta: { title: "巡检异常", name: "patrol_v2_error" },
        component: () => import("@/views/PATROL/inspectionWrong/index.vue"), // 路由懒加载写法 只要进入这个路径 才会加载这个路由
      },
      {
        path: "/PATROL_ALL/task-manage",
        meta: { title: "任务计划", name: "patrol_v2_task" },
        component: () => import("@/views/PATROL/taskManage/index.vue"), // 路由懒加载写法 只要进入这个路径 才会加载这个路由
      },
      {
        path: "/PATROL_ALL/PATROL-rang-manage",
        meta: { title: "巡检方案", name: "patrol_v2_scheme" },
        component: () => import("@/views/PATROL/rangeMange/index.vue"), // 路由懒加载写法 只要进入这个路径 才会加载这个路由
      },
      {
        path: "/PATROL_ALL/inspection-content",
        meta: { title: "巡检项", name: "patrol_v2_inspection_item" },
        component: () => import("@/views/PATROL/inspectionContent/index.vue"), // 路由懒加载写法 只要进入这个路径 才会加载这个路由
      },
      // {
      //     path: "/PATROL_ALL/device-mananger",
      //     meta: {title: "资产台账", name: "patrol_asset"},
      //     component: () => import("@/views/PATROL/deviceManager/index.vue"), // 路由懒加载写法 只要进入这个路径 才会加载这个路由
      // },
    ],
  },

  /*智慧排班*/
  {
    path: "/roster",
    component: Layout,
    meta: { title: "智慧排班", icon: "drainagepaiban" },
    // redirect:"/onlinemodel/mapauthorization",
    children: [
      {
        path: "/roster/dutySituation",
        component: () => import("@/views/roster/dutySituation/index.vue"),
        meta: { title: "值班情况", name: "schedule_on_duty" },
      },
      // {
      //   path: "/roster/runConfiguration",
      //   component: () => import("@/views/roster/runConfiguration/index.vue"),
      //   meta: { title:"运行配置", name: "water_model_gismap_registry" },
      // },
      {
        path: "/roster/dutyOffcer",
        component: () => import("@/views/roster/dutyOffcer/index.vue"),
        meta: { title: "值班人员", name: "schedule_member" },
      },
      {
        path: "/roster/shift-management",
        component: () => import("@/views/roster/shiftManagement/index.vue"),
        meta: { title: "排班管理", name: "schedule_manage" },
      },
      {
        path: "/roster/path",
        component: () => import("@/views/roster/historypath/index.vue"),
        meta: { title: "值班轨迹", name: "schedule_location" },
      },
      // {
      //   path: "/roster/dutyOffcer",
      //   component: () => import("@/views/roster/dutyOffcer/index.vue"),
      //   meta: {title:"值班人员",name:"schedule"}
      // },
    ],
  },

  /*
   * 漏控管理
   *
   * */
  {
    path: "/leakage-control",
    component: Layout,
    meta: { title: "管网管理", icon: "drainagesupervision" },
    // redirect:"/onlinemodel/mapauthorization",
    children: [
      {
        path: "/leakage-control/partition-setting",
        component: () =>
          import("@/views/LeakageControl/PartitionSetting/index.vue"),
        meta: { title: "数据资源", name: "dma" },
      },
      {
        path: "/leakage-control/monitoring-analysis",
        component: () =>
          import("@/views/LeakageControl/MonitoringAnalysis/index.vue"),
        meta: { title: "监控分析  ", name: "dma" },
      },
      {
        path: "/leakage-control/leakage-analysis",
        component: () =>
          import("@/views/LeakageControl/LeakageAnalysis/index.vue"),
        meta: { title: "漏损分析  ", name: "dma" },
      },
      // {
      //   path:"/controlCenter/testVedio",
      //   component: () => import("@/views/echartsLinkage/testVedio.vue"),
      //   meta:{title:"测试vedio  ",name:"ctl_plan"}
      // }
    ],
  },

  // 报表管理
  {
    path: "/report_manage",
    component: Layout,
    meta: { title: "报表管理", icon: "drainagecaiji" },
    redirect: "/report_manage/disposition",
    children: [
      {
        path: "/report_manage/disposition",
        component: () => import("@/views/reportmanage/disposition.vue"),
        meta: { name: "report_manage", title: "生产报表管理" },
      },
      {
        path: "/report_manage/present",
        component: () => import("@/views/reportmanage/present.vue"),
        meta: { name: "report_present", title: "生产报表查看" },
      },
    ],
  },

  // 位置管理
  {
    path: "/location_manage",
    component: Layout,
    meta: { title: "位置管理", icon: "drainagexunjianrenwu" },
    redirect: "/location_manage/index",
    children: [
      {
        path: "/location_manage/index",
        component: () => import("@/views/locationmanage/index.vue"),
        meta: { name: "location_manage" }, //name值是后端的资源
      },
    ],
  },

  /*高级控制*/
  {
    path: "/advancedControl",
    component: Layout,
    meta: { title: "高级控制", icon: "drainagea-fengxiankongzhi" },
    // redirect:"/onlinemodel/mapauthorization",
    children: [
      {
        path: "/advancedControl/mud",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "智慧排泥", name: "adv_model:智慧排泥",type:'智慧排泥' },
      },
      {
        path: "/advancedControl/waterForecast",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "水量预测", name: "adv_model:水量预测" ,type:'水量预测' },
      },
      {
        path: "/advancedControl/rawWater",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "产供水平衡", name: "adv_model:原水恒流" ,type:'原水恒流' },
      },
      // {
      //   path: "/advancedControl/rawWater2",
      //   component: () => import("@/views/advancedControl/mud/indexNew.vue"),
      //   meta: { title:"原水恒流2", name: "adv_model:原水恒流" },
      // },
      {
        path: "/advancedControl/PAC",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "精确加矾", name: "adv_model:PAC投加" ,type:'PAC投加'},
      },

      {
        path: "/advancedControl/precisionControl",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "精确过滤", name: "adv_model:精确过滤" ,type:'精确过滤'},
      },
      {
        path: "/advancedControl/SmartPump",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "智慧配泵", name: "adv_model:智慧配泵",type:'智慧配泵' },
      },
      {
        path: "/advancedControl/pipeNetwork",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "管网流量预警", name: "adv_model:管网流量预警",type:'管网流量预警'  },
      },

      {
        path: "/advancedControl/NaClO",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "精确加氯", name: "adv_model:次钠投加",type:'次钠投加' },
      },
      {
        path: "/advancedControl/onLineClear",
        //component: () => import("@/views/advancedControl/mud/indexNew.vue"),
        component: () => import("@/views/advancedControl/mud/advModelIndexV3.vue"),
        meta: { title: "在线清洗", name: "adv_model:在线清洗" ,type:'在线清洗'},
      },
      // {
      //   path: "/advancedControl/onLineClear",
      //   component: () => import("@/views/advancedControl/mud/indexNew.vue"),
      //   meta: { title: "在线清洗", name: "adv_model:在线清洗" },
      // },
      // {
      //   path: "/advancedControl/constantVoltage",
      //   component: () => import("@/views/advancedControl/mud/indexNew.vue"),
      //   meta: { title: "管网恒压", name: "adv_model:管网恒压" },
      // },
    ],
  },

  /**
   * 控制中心
   */
  {
    path: "/controlCenter",
    component: Layout,
    meta: { title: "控制中心", icon: "drainagesupervision" },
    // redirect:"/onlinemodel/mapauthorization",
    children: [
      {
        path: "/controlCenter/controlConfig",
        component: () =>
          import("@/views/controlCenter/controlConfig/index.vue"),
        meta: { title: "自控配置", name: "ctl_device" },
      },
      {
        path: "/controlCenter/controlCenter",
        component: () =>
          import("@/views/controlCenter/controlCenter/index.vue"),
        meta: { title: "控制中心  ", name: "ctl_plan" },
      },
      // {
      //     path: "/controlCenter/time-line",
      //     component: () => import("@/views/time-line/index.vue"),
      //     meta: {title: "时间轴  ", name: "ctl_plan"}
      // },
    ],
  },

  // 在线模型
  {
    path: "/onlinemodel",
    component: Layout,
    meta: { title: "在线模型", icon: "drainagemoxingku" },
    // redirect:"/onlinemodel/mapauthorization",
    children: [
      {
        path: "/onlinemodel/mapauthorization",
        component: () => import("@/views/onlinemodel/mapauthorization.vue"),
        meta: { title: "地图授权", name: "water_model" },
      },
      {
        path: "/onlinemodel/model-management",
        component: () => import("@/views/onlinemodel/modelmanagement.vue"),
        meta: { title: "模型管理", name: "water_model" },
      },
      {
        path: "/onlinemodel/drainage-model",
        component: () => import("@/views/onlinemodel/drainagemodel.vue"),
        meta: { title: "排水模型", name: "water_model" },
      },
    ],
  },

  /*
   *
   * 移动端配置
   * */
  {
    path: "/mobile-management",
    component: Layout,
    meta: { title: "移动端配置", icon: "drainagexitong-gongnengpeizhi" },
    children: [
      // app 关键指标配置
      {
        path: "/mobile-management/app-key-scada",
        component: () =>
          import("@/views/customiseConfig/app关键指标配置/index.vue"),
        meta: { title: "scada指标", name: "customize_app_config" },
      },
    ],
  },

  /*
   *
   * 资产管理
   * */
  {
    path: "/asset-management",
    component: Layout,
    meta: { title: "资产管理", icon: "drainagezichanguanli" },
    children: [
      {
        path: "/asset-management/lifecycle",
        component: () => import("@/views/资产管理/生命周期/index.vue"),
        meta: { title: "全生命周期", name: "asset_lifecycle" },
      },
      {
        path: "/asset-management/houtai",
        component: () => import("@/views/资产管理/生命周期/index.vue"),
        meta: {
          title: "后台管理",
          name: "asset_background_management",
          link: process.env.VUE_APP_JUMP_ASSETS,
          subPath: "/eam_equlistmanger/autoTemplates/eam_equmentlist",
        },
      },
      {
        path: "/asset-management/device-rate",
        component: () => import("@/views/设备完好率/index.vue"),
        meta: { title: "设备完好率", name: "asset_equ_ava" },
      },
    ],
  },


  /*
   * 物料管理 material
   * */
  {
    path: "/material-management",
    component: Layout,
    meta: {
      title: "物料管理",
      icon: "drainagewuliaoguanli",
      name: "stock_material",
      link: process.env.VUE_APP_JUMP_ASSETS,
      subPath: "/stock/autoTemplates/stock_material",
    },
    redirect: "/material-management/houtai",
    children: [
      {
        path: "/material-management/houtai",
        component: () => import("@/views/资产管理/生命周期/index.vue"),
        meta: {
          title: "物料管理",
          name: "stock_material",
          link: process.env.VUE_APP_JUMP_ASSETS,
          subPath: "/stock/autoTemplates/stock_material",
        },
      },
    ],
  },

  // {
  //   path: "/open-api",
  //   component: Layout,
  //   meta: { title: "开放接口", icon: "drainagexunjianrenwu" },
  //   redirect: "/open-api/index",
  //   children: [
  //     {
  //       path: "/open-api/index",
  //       component: () => import("@/views/开放接口/index.vue"),
  //       meta: { name: "openapi_ts" }, //name值是后端的资源
  //     },
  //   ],
  // },

];

// 静态路由
/**
 * constantRoutes
 * a base page that does not have permission requirements,不需要经过权限验证的路由
 * all roles can be accessed
 */
export const constantRoutes = [
  // 门户网站
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    meta: {
      title: "门户管理",
      icon: "drainagerhome-fill",
      name: "dashboard",
    },
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/home/Home.vue"),
      },
      {
        path: "homeBack",
        redirect: "dashboard",
        component: () => import("@/views/home/homeBack.vue"),
        hidden: true,
      },
    ],
  },

  {
    path: "/login",
    component: () => import("@/views/login/login.vue"),
    meta: { isMenu: false },
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
    meta: { isMenu: false },
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true,
    meta: { isMenu: false },
  },
  // 尝试在/api/sys/config获取失败时跳转本业，不成功
  {
    path: "/error",
    component: () => import("@/views/error-page/Error"),
    hidden: true,
    meta: { isMenu: false },
  },
  // 首页-门户管理
];
