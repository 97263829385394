import axios from "./axios";

export const commonApi = {
  commonApi: {
    // 导入
    importExcel(url, params) {
      return axios.post(url, params, {
        showLoading: false,
      });
    },
    // 导出
    exportExcel(url,params) {
      return axios.get(url, {
        responseType: "blob",
        params
        // headers: {
        //   "Content-Type": "application/json",
        // },
      });
    },
    importExcelGet(url, params) {
      return axios.get(url, params, {
        showLoading: false,
      });
    },
  },
};
